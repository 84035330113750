@import "bootstrap-variables";

.ng-select.bp-select {
    &.remove-arrow-wrapper {
        .ng-arrow-wrapper {
            display: none;
        }
    }

    &.ng-select-multiple {
        .ng-select-container {
            height: auto;

            .ng-value-container {
                .ng-placeholder {
                    color: #999;
                    font-size: 12px;
                    top: 8px;
                    left: 5px;
                }
            }
        }
    }

    &.fw-500 {
        font-weight: 500;

        .ng-select-container {
            .ng-value-container {
                .ng-placeholder {
                    font-weight: 500;
                }
            }
        }
    }

    &.fs-14 {
        font-size: 14px;

        .ng-select-container {
            .ng-value-container {
                .ng-placeholder {
                    font-size: 14px;
                }
            }
        }
    }

    &.heght-auto {
        .ng-select-container {
            min-height: max-content;
            height: auto;
        }
    }

    font-size: 12px;
    font-weight: 400;

    &.remove-underlines {
        .ng-select-container {
            border: none;
            border-bottom: 1px solid $gray-line-color;
            box-shadow: none;
            border-radius: 0;
        }

        &.no-bottom-border {
            .ng-select-container {
                border-bottom: none;
            }
        }

        .ng-select-container:hover {
            box-shadow: none;
        }
    }

    .ng-select-container {
        border: 1px solid $gray-line-color;
        border-radius: 5px;
        height: auto;
        min-height: 38px;
        font-size: inherit;
        box-shadow: none;

        .ng-value-container {
            .ng-placeholder {
                font-size: 12px;
            }

            .ng-input {
                & > input {
                    line-height: inherit;
                }
            }

            .ng-value {
                div {
                    line-height: 1.2 !important;
                }
            }
        }
    }

    input {
        height: initial;
        color: $dark-gray;
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: #cbd6e2;
    }

    .ng-clear-wrapper span {
        color: #cbd6e2;
    }

    .ng-option-label {
        white-space: normal;
    }

    .ng-dropdown-panel .scroll-host {
        min-height: 35px;
    }

    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            .ng-option {
                border-bottom: none;

                &.ng-option-selected {
                    color: var(--primary);
                    background-color: white;
                }
            }
        }
    }

    &.hide-arrow {
        .ng-arrow-wrapper {
            display: none;
        }
    }
}

@import 'bootstrap-variables';

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body,
h1,
h2,
h3,
h4 {
    font-weight: 500;
}

a {
    color: #533f03;
    font-weight: bold;
}

a:hover {
    color: #533f03;
    font-weight: bold;
    /* make sure browsers use the pointer cursor for anchors, even with no href */
    cursor: pointer;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
/*.ng-valid[required],
.ng-valid.required {
    border-left: 5px solid green;
}

.ng-invalid:not(form) {
    border-left: 5px solid red;
}*/

/* other generic styles */

.jh-card {
    padding: 1.5%;
    margin-top: 20px;
    border: none;
}

.error {
    color: red;
    background: transparent;
}

input.error {
    color: initial;
}

.form-group label.error {
    background-color: transparent;
}

.pad {
    padding: 10px;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.break {
    white-space: normal;
    word-break: break-all;
}

.readonly {
    background-color: #eee;
    opacity: 1;
}

.footer {
    color: #afb6c4;
    // border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[jhisortby] {
    cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
    .alert {
        text-overflow: ellipsis;

        pre {
            background: none;
            border: none;
            font: inherit;
            color: inherit;
            padding: 0;
            margin: 0;
        }

        .popover pre {
            font-size: 10px;
        }
    }

    .toast {
        position: fixed;
        width: 100%;

        &.left {
            left: 5px;
        }

        &.right {
            right: 5px;
        }

        &.top {
            top: 55px;
        }

        &.bottom {
            bottom: 55px;
        }
    }
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
    @if $side == 'all' {
        .no-padding {
            padding: 0 !important;
        }
    } @else {
        .no-padding-#{$side} {
            padding-#{$side}: 0 !important;
        }
    }
}

@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
    width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > {
    dd {
        margin-bottom: 15px;
    }
}

@media screen and (min-width: 768px) {
    .row.jh-entity-details > {
        dt {
            margin-bottom: 15px;
        }

        dd {
            border-bottom: 1px solid #eee;
            padding-left: 180px;
            margin-left: 0;
        }
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
    outline: none;
}

.thread-dump-modal-lock {
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.modal {
    background-color: transparent;
    border: none;
    box-shadow: none;

    .modal-footer {
        background-color: transparent;
        border: none;
    }
}

.form-check {
    line-height: 26px;
}

.btn-inverted {
    background-color: transparent !important;
    color: #2f74ba !important;
    font-weight: bold;
}

.btn-inverted:hover {
    color: #3481cd !important;
}

.btn-inverted:active {
    color: #3481cd !important;
}

.btn-inverted:focus {
    color: #2f74ba !important;
}

.btn-inverted:disabled {
    color: #0076c0 !important;
}

.breadcrumbs {
    color: #1f104d;
    font-size: 13px;
    font-weight: bold;
}

.breadcrumbs:hover {
    color: var(--primary);
}

.material-icons {
    vertical-align: middle;
}

.material-icons.colored {
    color: #0076c0;
}

.material-icons.colored:disabled {
    color: #99b0c9;
}

.cursor-pointer {
    cursor: pointer;
}

.btn.bp-label-button {
    background: transparent;
    color: #0076c0;
    font-weight: 700 !important;
}

.bp-delete-button {
    color: #bc1e1e !important;
    font-size: 14px;
    font-weight: bold;

    span {
        vertical-align: middle;
    }
}

.bp-icon-button {
    background: transparent;
    border: none;
    cursor: pointer;

    span {
        color: #2f74ba;
        font-size: 13px;
        font-weight: 500;
        vertical-align: top;
    }
}

.bp-icon-button:focus {
    background: transparent;
}

.add-item-container {
    .add-item {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;

        span {
            vertical-align: top;
        }

        i {
            color: #0076c0;
        }
    }
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

/* Turn off up down arrows to number inputs */

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a.output-report,
a.export-as-pdf,
a.export-as-excel {
    color: inherit;
}

a.output-report i,
a.export-as-pdf i,
a.export-as-excel i {
    vertical-align: text-top;
}

.bp-btn-group {
    .btn {
        width: 35px;
        height: 35px;
        padding: 0;
    }
}

.flex-line {
    display: flex;

    input.default-price {
        width: 170px;
    }

    &.column-direction {
        flex-direction: column;
    }

    &.has-units {
        margin-right: -1px;

        input {
            padding-right: 20pt;
            text-align: right;
        }

        input.first {
            border-radius: 6px 0 0 6px;
        }

        input.last {
            border-radius: 0 6px 6px 0;
        }

        input:focus {
            z-index: 1000;
        }

        input:focus + span {
            z-index: 1001;
        }

        span {
            line-height: 36px;
            font-size: 14px;
            font-weight: 500;
            color: #cbcfd1;
            margin-left: -15px;
        }

        span.pound-symbol {
            position: absolute;
            margin-left: 7px;
        }
    }
}

.bp-cursor-pointer {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.row.no-margin {
    margin: 0;
}

.row.no-padding {
    padding: 0;
}

.bold {
    font-weight: normal;
    font-size: 14pt;
    margin: 0;
}

@media (max-width: 1024px) {
    .container-fluid {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.full-width {
    width: 100%;
}

.display-flex {
    display: flex;
}

.link {
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    color: var(--primary) !important;
}

.link.underlined {
    text-decoration: underline !important;
}

.link:hover {
    color: #006486 !important;
}

.no-border-bottom {
    border-bottom: none !important;
}

section.content {
    width: calc(100% - 10px);
    margin: 60px auto 0;
    padding-top: 20px;
}

@media (max-width: 768px) {
    section.content {
        width: calc(100% - 20px);
    }
}

@media (max-width: 400px) {
    section.content {
        width: calc(100% - 6px);
    }
}

.w-full {
    width: 100%;
}

.flex-between-container {
    display: flex;
    justify-content: space-between;
}

.gap-5px {
    gap: 5px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.gap-20 {
    gap: 20px;
}

.gap-30 {
    gap: 30px;
}

.gap-40 {
    gap: 40px;
}

.gap-50 {
    gap: 50px;
}

.gap-60 {
    gap: 60px;
}

.gap-53 {
    gap: 53px;
}

.gap-100 {
    gap: 100px;
}

.gap-200 {
    gap: 200px;
}

.gap-100-column {
    column-gap: 100px;
}

.gap-20-row {
    row-gap: 20px;
}

.flex-end-container {
    display: flex;
    justify-content: flex-end;
}

.flex-1 {
    flex: 1;
}

.flex-layout {
    display: flex;
}

.items-center {
    align-items: center;
}

.clickable:hover {
    cursor: pointer;
}

.ellipsis-overflow-container {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ellipsis-overflow-container-2 {
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    white-space: break-spaces;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
}

.ellipsis-overflow-container-3 {
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    white-space: break-spaces;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-wrap: break-word;
}

.ellipsis-overflow-container-2-no-max-w {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    white-space: break-spaces;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
}

.line-height-1 {
    line-height: 1;
}

.gray-text {
    color: #858585;
}

.table-responsive {
    th,
    td {
        font-size: 12px;
    }
}

/* this allows us to hide the unstyled text while the icons are loading */
.wf-loading, .wf-materialicons-n4-inactive {
    .material-icons {
        display: none
    }
}

.flex-0_5 {
    flex: 0.5;
}

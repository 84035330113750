@import "bootstrap-variables";

.fixed-footer-mobile {
    display: none;

    &.visible-in-normal-view-on-desktop {
        display: block;
    }
}

@media (max-width: 768px) {
    .main-fixed-container {
        max-height: calc(100vh - 150px);
        width: 100%;
        justify-content: center !important;
        gap: 20px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 10px 10px;
    }

    .fixed-footer-mobile {
        background: white;
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        border-top: 1px solid #deeaef;
        padding: 10px;
    }
}

@media (max-width: 400px) {
    .main-fixed-container {
        padding: 0;
    }
}


/*
* Bootstrap overrides https://getbootstrap.com/docs/4.0/getting-started/theming/
* All values defined in bootstrap source
* https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss can be overwritten here
* Make sure not to add !default to values here
*/

// Colors:
// Grayscale and brand colors for use across Bootstrap.

:root {
    --primary: #0086aa;
}

$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

$black: rgb(1, 69, 84);
$dark-blue: #0086aa;
$dark-gray: #6c757d;
$gray: #979797;

// Extra colors
$extraColorYellow: #FFC250;
$extraColorOrange: #FF7600;
$extraColorPink: #F04DCA;
$extraColorMagenta: #7C00E6;
$extraColorBlue: #1B14DB;
$extraColorGreen: #9ED93F;

// Lines
$gray-line-color: #cbcfd1;
$gray-line-width: 1px;

$blue-line-color: $dark-blue;
$blue-line-width: 1px;

$button-line-color: #aed9e4;
$button-line-width: 1px;

// Options:
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Components:
// Define common padding and border radius sizes and more.

$border-radius: 0.4375rem;
$border-radius-lg: 0.125rem;
$border-radius-sm: 0.1rem;

// Body:
// Settings for the `<body>` element.

$body-bg: #e4e5e6;
$text-gray: #858585;

$header-color: #034655;

// Typography:
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 1rem;

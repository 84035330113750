@import "bootstrap-variables";

.project-card-container {
    width: 300px;
    height: 300px;
    border: $gray-line-width solid $gray-line-color;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;

    &:hover {
        background: #f9f9f9;
    }

    padding: 15px;
    background-color: white;
}

@media (max-width: 768px) {
    .project-card-container.non-admin-card {
        width: 100%;
        height: 130px;
        padding: 0;
    }
}

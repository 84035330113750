@import "bootstrap-variables";

.use-main-bp-background {
    background-image: url('../../content/images/pb_background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 60px);
}

@media (max-width: 768px) {
    .use-main-bp-background {
        background: none;
    }
}


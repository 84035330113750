@import "bootstrap-variables";

.dropdown.bp-dropdown {
    .dropdown-menu {
        border: 1px solid var(--primary);
    }

    button, a {
        color: var(--primary);
    }
}

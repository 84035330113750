@import "bootstrap-variables";

button {
    cursor: pointer;

    &.bp-icon {
        width: 36px;
        height: 36px;
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
    }

    &.bp-pdf-icon {
        background-image: url('../../content/images/icons/buttons/pdf.png');
    }

    &.bp-excel-icon {
        background-image: url('../../content/images/icons/buttons/excel.png');
    }

    &.bp-add-list-icon {
        background-image: url('../../content/images/icons/buttons/add-list.png');
    }

    &.bp-schedule-icon {
        background-image: url('../../content/images/icons/buttons/view_list_black.svg');
        background-size: auto;
    }

    &.bp-expand-icon {
        background-image: url('../../content/images/icons/buttons/expand.png');
    }

    &.bp-collapse-icon {
        background-image: url('../../content/images/icons/buttons/collapse.png');
    }

    &.bp-edit-areas-icon {
        background-image: url('../../content/images/icons/buttons/edit-areas.png');
    }

    &.bp-show-substages-icon {
        background-image: url('../../content/images/icons/buttons/show-substages.png');
    }

    &.bp-specific-margin-action {
        background-image: url('../../content/images/icons/buttons/specific-margin-action.png');
    }

    &.bp-remove {
        background-image: url('../../content/images/icons/buttons/remove.png');
    }

    &.google-auth {
        border: $gray-line-width solid $gray-line-color;
        border-radius: 5px;
        height: 36px;
        box-shadow: none;
        background: white;

        font-weight: 500;
    }
}

.btn {
    &.btn-default.previous {
        background: none !important;
        color: $black !important;
        font-weight: 500;
        text-align: left;
        padding-left: 0;
        margin-left: 0;
    }

    &.btn-primary.next {
        background-color: #45d391;
        color: white;
        border: none;
    }
}

.icon-button-18 {
    cursor: pointer;
    width: 18px;
    height: 18px;
    background: transparent;
    border: none;
}

.icon-button-24 {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
}

.icon-button-primary {
    color: var(--primary);
}

@import "bootstrap-variables";

.form-check.clear {
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;

    .form-check-label {
        position: initial;
        cursor: pointer;
        padding-left: 35px;
        line-height: 26px;
        margin-bottom: 0;
        transition: color 0.3s linear;
    }

    .form-check-label span {
        padding: 0;
        margin: 0;
        height: 20px;
        width: 20px;
        top: 1px;
    }

    .form-check-sign .check {
        border: 1px solid rgb(0 0 0 / 28%);
    }

    .form-check-input:checked + .form-check-sign .check {
        background: rgb(0, 71, 81);
    }
}

.form-check-sign::after {
    display: none;
}

@import "bootstrap-variables";

.swal2-container {
    width: 100% !important;

    .swal2-popup {
        padding: 20px;
        color: $black;

        .swal2-close {
            position: absolute;
            top: 15px;
            right: 15px;

            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1;
            color: #000;
            text-shadow: 0 1px 0 #fff;
            opacity: 0.5;
        }

        .link {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.5;
            text-align: center;
            text-decoration: none;
            color: var(--primary);
        }

        .link.underlined {
            text-decoration: underline !important;
        }
    }

    &.ask-for-registration-banner {
        .swal2-popup.swal2-toast {
            padding: 10px 0;
        }

        .swal2-popup.swal2-toast .swal2-actions {
            margin-top: -40px;
            justify-self: right;
        }
    }

    .swal2-styled.swal2-confirm {
        background-color: var(--primary);
    }

    .swal2-popup.swal2-toast {
        width: 500px !important;
        opacity: 1;

        &.swal2-icon-success {
            background: lightgreen !important;

            .swal2-success-ring {
                border: 0.25em solid white;
            }

            .swal2-icon.swal2-success [class^='swal2-success-line'] {
                background-color: white;
            }
        }

        .swal2-actions.bp-actions {
            button {
                border: 2px solid #0086AA;
                border-radius: 10px;
            }

            .confirm-btn {
                background: #0086AA;
            }

            .close-btn {
                color: #0086AA;
                background: transparent;
            }
        }
    }

    @media (max-width: 768px) {
        &.swal2-top {
            width: 100%;
        }

        .swal2-container {
            &.ask-for-registration-banner {
                .swal2-popup.swal2-toast .swal2-actions {
                    margin-top: auto;
                    justify-self: center;
                }
            }
        }
    }

    @media (max-width: 600px) {
        &.ask-for-registration-banner {
            .swal2-popup.swal2-toast .swal2-actions {
                margin-top: auto;
                justify-self: center;
            }
        }
    }
}

@import "bootstrap-variables";

.fw-400 {
    font-weight: 400;
}

.f-12-400-gray {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $text-gray;
}

@import "bootstrap-variables";

.modal.bp-modal {
    z-index: 1050;

    .modal-footer {
        padding: 0 20px;
        margin-bottom: 20px;
    }

    .modal-header {
        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            color: $dark-blue;
        }
    }

    .modal-body {
        color: $black;
    }

    &.blue-modal {
        width: auto;

        .modal-header {
            text-align: center;
            display: flex;
            justify-content: center;
            color: var(--primary);
            border-bottom: none;
            font-weight: bold;
        }
    }

    &.how-to-create-project-modal {
        max-height: 100%;

        .modal-content {
            width: fit-content;
        }
    }

    &.service-selector-modal {
        max-height: 99%;
        width: max-content;
    }

    &.task-request-modal {
        .modal-content {
            padding: 10px;
        }
    }

    &.review-by-qs {
        .modal-content {
            width: 600px;
            padding: 20px;
        }
    }

    &.builder-has-been-invited-to-quote {
        .modal-content {
            width: 450px;
            padding: 10px;
        }
    }

    &.fit-content {
        .modal-dialog {
            min-width: fit-content;
        }
    }

    &.thanks-for-subscribing {
        .modal-content {
            width: 566px;
        }
    }

    &.submit-for-analysis-modal {
        max-height: 100%;
        padding: 0;

        .modal-footer {
            padding: 0;
        }
    }

    &.after-signup-wizard-modal {
        .modal-footer {
            height: auto;
        }
    }
}

@media (max-width: 768px) {
    .modal.bp-modal {
        width: 100% !important;

        &.review-by-qs {
            .modal-content {
                width: 100%;
                padding: 10px;
            }
        }
    }
}

#app-loading {
    background: url('/content/images/loading/loading-spinner.gif') no-repeat center;
    background-size: initial;
    width: 100%;
    height: calc(100vh);

    position: absolute;
    top: 0;
    left: 0;
}

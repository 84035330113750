@import "bootstrap-variables";

.bp-popper {
    &.ngxp__container {
        z-index: 3;
        box-shadow: none;
        width: max-content;

        background-color: white;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: $button-line-width solid $button-line-color;

        font-size: 12px;
        padding: 0;

        .ngxp__inner {
            background: white;
            border-radius: 5px;
        }
    }

    &.ngxp__container>.ngxp__arrow:before {
        transform: rotate(45deg);
        content: "";
        background-color: var(--primary);
        top: 0;
        left: 0;
    }
}
